<template>
  <Dialog :name="name" width="800px" class="change-column">
    <template #title>
      <div>Изменение колонок таблицы</div>
    </template>
    <el-transfer
      v-model="value"
      :titles="['Доступно к отображению', 'Отображается']"
      :data="fullList"
    />

    <div class="change-column__btns">
      <iq-button size="small" color="gray" @onClick="handleClose">
        Отменить
      </iq-button>

      <iq-button size="small" @onClick="handleConfirm"> Подтвердить </iq-button>
    </div>
  </Dialog>
</template>

<script>
import {
  GET_PARKMAN_TABLE_FIELDS,
  SAVE_PARKMAN_TABLE_FIELDS,
} from '@/views/parkman/store/actions'
import { PARKMAN_CHANGE_TABLE_COLUMN } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import { parkmanTableFields } from '@/views/parkman/data/constants'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/views/ui/components/buttons/IqButton'

export default {
  name: 'DialogChangeTableColumn',
  components: { IqButton, Dialog },
  data() {
    return {
      name: PARKMAN_CHANGE_TABLE_COLUMN,
      fullList: parkmanTableFields,
      value: [],
    }
  },
  computed: {
    ...mapGetters({
      getActiveColumns: GET_PARKMAN_TABLE_FIELDS,
    }),
  },
  watch: {
    getActiveColumns: {
      immediate: true,
      handler(val) {
        this.value = val
      },
    },
  },
  methods: {
    ...mapActions({
      saveColumns: SAVE_PARKMAN_TABLE_FIELDS,
    }),
    handleClose() {
      this.setDialog({ name: this.name })
    },
    handleConfirm() {
      this.saveColumns(this.value)
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.change-column
  .el-transfer
    display: flex
    justify-content: space-between
    align-items: center

  .el-transfer-panel
    width: 290px

  &__btns
    margin-top: 20px
    display: flex
    justify-content: space-around
    align-items: center
</style>
